import { Dialog } from 'vant';
export default {
  name: 'logistics-popup',
  props: {

    // 弹窗显示内容的状态码
    status: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      state: 0,//0:溯源码重复
    }
  },


  methods: {
    //确认发货
    send() {
      this.$emit("send");
    },
    // 关闭页面
    finish() {
      this.$emit('closePopup');
    },
    //重新录入
    scanagain() {
      this.$emit('scanagain');
    }
  }
}
