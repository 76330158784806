/**
 * 代理详情
 */
import { Dialog, Toast } from "vant";
import { mapState } from "vuex";
import { scancode } from "@/utils/wx-promise";
import logisticsPopup from "@/components/order/logistics-popup/index.vue";
import { getUrlParams } from "@/utils/common.js";
import { createOrDeliverSuborder } from "@/service/retail-order/index.js";

export default {
  name: "retail-dispatch-terminal",
  components: { logisticsPopup },

  data() {
    return {
      sourceCode: "",   //溯源的码
      sourceCodeList: [],   //溯源码列表
      detailToggle: false, //false表示不全部显示订单清单，true 表示全显
      row: {}, //当前正在操作的审核订单
    };
  },

  async mounted() {

  },

  computed: {
    ...mapState({
      userId: (state) => state.user.user.id, // 我的ID
      address: (state) => state.retail.addressInfo,
      products: (state) => state.retail.productList,
    }),
  },

  methods: {
    //获取溯源码
    async getLogisticsSource() {
      let code = await scancode();
      console.log("code", code);
      const params = getUrlParams(code);
      if (code.indexOf("?") >= 0 && params["fw"]) {
        //  加入判断，如果识别出来的是网址，解析网址 http://cx.1.net/?fw=9834085332679670
        this.sourceCode = params["fw"];
      } else {
        this.sourceCode = code.split(",")[1] || code;
      }
    },

    // 将溯源码添加到朔源码列表中
    async addSourceCode() {
      const code = this.sourceCode && this.sourceCode.trim();
      if (!this.validateSourceCode(this.sourceCode && this.sourceCode.trim())) {
        Toast("该溯源码不是12位或者16位不能发货");
        return false;
      }
      if (this.sourceCode.trim()) {
        // 校验朔源码是否有效，如果有，则添加
        if (
          this.sourceCodeList.find((e) => {
            return e.sourceCode === this.sourceCode;
          })
        ) {
          Toast("该溯源码已经在列表中");
          return;
        }
        // 暂时不调用接口，修改自己测试
        // const { codeList } = await checkSourceCode({ tracingSourceCode: this.sourceCode });
        // switch (codeList[0].state) {
        //   // 匹配成功，type为1表示盒
        //   case '1':
        //   case 1:
        //     this.sourceCodeList.push({ sourceCode: this.sourceCode, size: `1${codeList[0].type ==1 ? '盒' : '箱'}`, type: codeList[0].type, });
        //     Toast(codeList[0].message);
        //     break;

        //   default:
        //     Toast(codeList[0].message);
        //     break;
        // }
        this.sourceCodeList.push({
          sourceCode: this.sourceCode,
          size: `1箱`,
          type: "1",
        });
      }
    },

    //校验溯源码是否正确
    validateSourceCode(sourceCode) {
      if (!/^(\d{16}|\d{12})$/.test(sourceCode)) {
        Toast(`存在溯源码${sourceCode}不是12位数字或者16位数字，不能发货～`);
        return false;
      }
      return true;
    },

    // 确定订单
    async send() {
      const { userId, address, products } = this;
      if (this.sourceCodeList.length == 0 || !this.sourceCodeList) {
        Toast("至少需要一个溯源码");
        return;
      }
      try {
        await Dialog.confirm({ message: '确定发货吗～' });
        let sourceList = {};
        this.sourceCodeList.forEach((e) => {
          sourceList[e.sourceCode] = e.type;
        });
        await createOrDeliverSuborder({
          userId: userId,
          addressInfo: address,
          productList: products,
          sourceCode: sourceList
        }).then((res) => {
          this.$router.push({
            name: "retail-delivery-success",
            query: { orderId: res.code },
          });
        });
      } catch (error) {
        console.log(error);
      }
    },

  },

};
